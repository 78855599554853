import logo from './logo.svg';
import './App.css';
import PWAPrompt from 'react-ios-pwa-prompt'

function App() {
  return (
    <div className="App">
      
        <PWAPrompt promptOnVisit={1} timesToShow={10} copyClosePrompt="Close" permanentlyHideOnDismiss={false} debug={false} 
        copyTitle = "Installa come un' App"
        copyBody = "Questa pagina ha i requisiti di una Web APP. Aggiungi alla tua Home per accedere ogni volta che sarà utile."
        copyShareButtonLabel = "1) Clicca l’icona 'Condividi' nella barra qui sotto."
        copyAddHomeButtonLabel = "2) Clicca su 'Aggiungi a Home'."
        copyClosePrompt = "Chiudi"
        />
      
    </div>
  );
}

export default App;
